import axios from "axios";

//  Server api access
export default axios.create({
  baseURL: "http://62.171.153.83:8080/carib-bargains-api/",
  headers: {
    "Content-Type": "application/json",
  },
});

//  Local api access
// export default axios.create({
//   baseURL: "http://localhost:8080/",
//   headers: {
//     "Content-Type": "application/json",
//   },
// });