import React, { useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Card, Typography, Stack, Switch, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Modal from 'react-bootstrap/Modal';
import Avatar from '@mui/material/Avatar';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import styl from './styl.css'


// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, productImages, price, colors, title, status, priceSale, createdAt, userResponse, description, updatedAt, featured,
    featuredExpDate, likeCount, sold, contactName, contactPhone, contactPhotoUrl, productAddresses, id

  } = product;
  const cover = productImages[0].imageUrl
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showproduct, setShowproduct] = useState(false);
  const handleCloseproduct = () => setShowproduct(false);
  const handleShowproduct = () => setShowproduct(true);



  return (
    <Card variant="outlined">
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <Label
          variant="filled"
          color={status === true ? 'info' : 'error'}
          sx={{
            zIndex: 9,
            top: 0,
            right: 0,
            position: 'absolute',
            textTransform: 'uppercase',
            width: "40px",
            height: "40px",
            borderRadius: "0% 20% 0% 20%"
          }}
        >
          {status === true ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
        </Label>

        <Label
          variant="filled"
          color={'primary'}
          sx={{
            zIndex: 9,
            left: 0,
            top: 0,
            position: 'absolute',
            textTransform: 'uppercase',
            width: "85px",
            height: "25px",
            borderRadius: "0px 0px 10px 0px"
          }}
        >
          User ID: {userResponse.id}
        </Label>

        <Label
          variant="filled"
          color={'primary'}
          sx={{
            zIndex: 9,
            left: 0,
            bottom: 0,
            position: 'absolute',
            textTransform: 'uppercase',
            borderRadius: "0px 10px 0px 0px"
          }}
        >
          {dayjs(createdAt).format("YY/MM/DD")}
        </Label>
        <Link color="inherit" underline="hover" state={{ item: product }} >
          <StyledProductImg alt={name} src={cover} onClick={handleShowproduct} />
        </Link>

      </Box>

      <Stack spacing={2} sx={{ p: 1 }}>
        <Link color="inherit" underline="hover" style={{ textDecoration: "none" }} onClick={handleShow}>
          <Typography variant="subtitle1" noWrap style={{ color: "#000", }}>
            {title}
          </Typography>
        </Link>


        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {/* <ColorPreview colors={colors} /> */}

          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                textDecoration: 'line-through',
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography>
            &nbsp;
            {fCurrency(price)}
          </Typography>
          <Stack>

            {status === true ? <Button variant="outlined" color='error' onClick={handleShow}>Block</Button> : <Button variant="outlined">Unblock</Button>}
          </Stack>
        </Stack>

      </Stack>
      <Modal show={showproduct} onHide={handleCloseproduct} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{id}. {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ borderBottom: "var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color)" }}>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="space-between">
            <Stack style={{ width: "33.33%" }}><b>User Name:</b> {userResponse.name}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Email:</b> {userResponse.email}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Mobile:</b> {userResponse.mobile}</Stack>
          </Stack>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="space-between" marginTop={1}>

            <Stack style={{ width: "33.33%" }}><b>Price:</b> {price}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Created at:</b> {dayjs(createdAt).format("YY/MM/DD")}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Featured:</b> {featured === true ? "Yes" : "No"}</Stack>
          </Stack>

          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="space-between" marginTop={1}>

            <Stack style={{ width: "33.33%" }}><b>Featured Expire Date:</b> {dayjs(featuredExpDate).format("YY/MM/DD")}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Status:</b> {status === true ? "Published" : "Unpublished"}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Blocked:</b> {status === true ? "Yes" : "No"}</Stack>
          </Stack>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="start" marginTop={1}>
            <Stack style={{ width: "33.33%" }}><b>View Count:</b> 5</Stack>
            <Stack style={{ width: "33.33%" }}><b>Like Count:</b> {likeCount}</Stack>
            <Stack style={{ width: "33.33%" }}><b>Sold:</b> {sold === true ? "Yes" : "No"}</Stack>
          </Stack>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="start" marginTop={1}>
            <Stack style={{ width: "33.33%" }}><b>Contact Person</b> {contactName}</Stack>
            <Stack style={{ width: "33.33%" }}><b>&nbsp;</b> {contactPhone}</Stack>
            <Stack style={{ width: "33.33%" }}><b>&nbsp;</b> ayush.s@mipl.us</Stack>
          </Stack>
          <Stack className='bolderHead' direction="row" alignItems="center" justifyContent="start" marginTop={1}>
            <Stack><b>Contact Address:</b> {productAddresses.state},{productAddresses.city},{productAddresses.country}</Stack>
          </Stack>
        </Modal.Body>
        <Modal.Body style={{ borderBottom: "var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color)" }}>
          <b>Description</b><br />
          {description}</Modal.Body>
        <Modal.Body >
          <ImageList sx={{ width: "100%", height: 250 }} cols={4} rowHeight={164}>
            {productImages.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  src={`${item.imageUrl}?w=164&h=164&fit=crop&auto=format`}
                  srcSet={`${item.imageUrl}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.id}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseproduct}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleCloseproduct}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>


  );
}
