import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
// @mui
import {
    Container,
  Stack,
  Typography,
} from '@mui/material';
// components
import Iconify from '../components/iconify';

export default function ViewUser(props) {

  console.log(props.id)
  console.log(props.data);
  
    const [id, setId] = useState(props.id);
    const [name, setName] = useState(props.name);
    const [email, setEmail] = useState(props.email);
    const [mobile, setMobile] = useState(props.mobile);
    const [image, setImage] = useState(props.imageUrl);
  return (
    <>
      <Helmet>
        <title> User | View </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography
           variant="h4" gutterBottom>
            Update User
          </Typography>
        </Stack>
      </Container>
    </>
  );
}
