import http from "../http-common";
import authHeader from "./auth-header";


const getAll = () => http.get("/admin/users", { headers: authHeader() });

const getPublicAll = () => http.get("/users");


const get = (id) => http.get(`/users/${id}`);


const create = (data) => {
  console.log(data);
  return http.post("/users", data);
};

const update = (id, data) => http.post(`/users/edit/${id}`, data);


const remove = (id) =>  http.post(`/users/delete/${id}`);

const findNearestLocation = (address, latitude, longitude) => http.get(`/users/search?address=${address}&latitude=${latitude}&longitude=${longitude}`);


// const upload = (file, email, onUploadProgress) => {
//   let formData = new FormData();

//   formData.append("file", file);
//   formData.append("email", email);

//   return http.post("/users/uploadFile", formData, {
//     headers: {
//       "Content-Type": "multipart/form-data",
//     },
//     onUploadProgress,
//   });
// };

const getPaginationAll = (params) => http.get("/user/all", { params });

const getPaginationWithAddrAndCompany = (params) => http.get("/user/all", { params });


const getGlobalSearch = (params) => http.get("/users/paging", { params });
const getProduct = (params) => http.get("/product/all");


const UserServices = {
  getAll,
  get,
  create,
  update,
  remove,
  findNearestLocation,
//   upload,
  getPaginationAll,
  getPublicAll,
  getGlobalSearch,
  getPaginationWithAddrAndCompany,
  getProduct
};

export default UserServices;
