// ----------------------------------------------------------------------

const account = {
  displayName: 'Jaydon Frankie',
  email: 'demo@minimals.cc',
  photoURL: '/assets/images/avatars/avatar_default.jpg',
  role:'Admin'
};

export default account;
